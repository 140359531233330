<template>
	<div class="serviceBody">
		<div class="sidebar">
			<van-sidebar v-model="activeBar.id">
				<van-sidebar-item v-for="(item, index) in barList" :key="index"
					:class="[item.id == activeBar.id ? 'active_sidebar' : '']" v-on:click="changeBar(item)"
					:title="item.text" />
			</van-sidebar>
		</div>
		<div class="serviceMain">
			<!--头部-->
			<div class="serviceHead" :style="this.$store.state.bgColor">

				<!--左-->
				<div class="serviceHeadLeft">
					<div class="serviceHeadImg">
						<img :src="headImg" />
					</div>
					<!--昵称及修改昵称-->
					<div class="serviceHeadName">
						昵称：{{ service.serviceName }}
					</div>

					<!-- <div class="serviceHeadNameNone" style="margin-left:10px;margin-right:10px">接待次数：{{ service.serviceFrequency
                }}</div> -->
				</div>
			</div>
			<!--主内容-->
			<div class="context">
				<!--左边内容-->
				<div class="conLeft">
					<!--在线用户列表-->
					<ul>
						<!--显示在线连接列表-->
						<li :key="index" v-for="(item, index) in users"
							style="cursor: pointer" v-on:click="selectSession(item)"
							:class="item.isSelectSession?'offlineUlStyle':''" v-on:mouseenter="item.CloseSession = true" 
							v-on:mouseleave="item.CloseSession = false">
							<div class="liLeft" v-if="item.userState==1">
								<img src="../assets/images/visitor1.png" />
							</div>
							<div class="liLeft" v-else>
								<img src="../assets/images/visitor.png" />
							</div>
							<div class="liRight">
								<!--显示用户名-->
								<span class="intername">{{ item.userName }}</span>
								<!--显示最新一条消息-->
								<span class="infor">{{ item.lastRegion }}</span>
								<!--显示小红点-->
								<div v-show="item.UnRead > 0" class="un_read">
									{{ item.UnRead > 99 ? "99+" : item.UnRead }}
								</div>
							</div>
						</li>
					</ul>

					<!--列表为空-->
					<div v-show="users.length === 0" class="noPeople">
						<img src="../assets/images/noPeple1.png" />
						<div style="text-align: center">等待接入...</div>
					</div>
				</div>
				<!--左部->中间内容-->
				<!--没有用户连接-->
				<div v-if="users.length === 0" class="conRight">
					<div class="layout-empty">
						<div class="layout-empty-conatiner">
							<img style="width: 100px; height: auto" src="../assets/images/noPeple.png" />
							<span>没有会话内容</span>
							<span style="color: #bdc3d1">当客户接入后，从左侧客户列表中选择客户开始会话</span>
						</div>
					</div>
				</div>
				<!--有用户连接，但没有点击-->
				<div v-else-if="isSelectShow" class="conRight">
					<div class="layout-empty">
						<div class="layout-empty-conatiner">
							<span>提示</span>
							<span style="color: #bdc3d1">点击左侧列表选择用户开始会话！</span>
						</div>
					</div>
				</div>
				<!--会话窗口-->
				<div v-show="isSelectSession" class="conRight">
					<!--会话头部-->
					<div class="Righthead">
						<div class="headName">{{ selectUser.userName }}</div>
						<div style="float:right;margin-right: 15px">
							<div style="margin-top: 12px; float: left;">
								<van-switch v-model="mode" size="20px" v-on:click="changeMode">
								</van-switch>
							</div>
							<div class="serviceStateDiv">
								<div :class="mode ? 'serviceStateGreenDot' : 'serviceStateRedDot'"></div>
								{{ mode ? "当前会话" : "历史会话" }}
							</div>
						</div>
						
					</div>
					<!--聊天内容-->
					<MessageWindow v-if="isSelectSession" id="RightCont" :messageList="selectUser.messageList"
						class="chatMessage" :sendId="service.serviceId" :receiveId="selectUser.receiveId"
						:isService="'true'" :serviceHead="service.headImg">
					</MessageWindow>
					<!--聊天框底部-->
					<div class="RightFoot">
						<!--<div  class="notAllowSeesion"></div>-->
						<div class="sendContent">
							<!--表情包-->
							<SendEmote v-show="expressionShow" @sendMessage="sendMessage"></SendEmote>
							<!--工具栏-->
							<div class="footTop">
								<ul>
									<li v-on:click="expressionShow = !expressionShow">
										<img src="../assets/images/expression.png" />
									</li>
									<li v-on:click="expressionShow = false" class="ExP" style="position: relative">
										<img src="../assets/images/imageFile.png" />
										<SendImage @sendMessage="sendMessage"></SendImage>
									</li>
									<li v-on:click="expressionShow = false" class="ExP" style="position: relative">
										<img src="../assets/images/videoFile.png" />
										<SendVideo @sendMessage="sendMessage"></SendVideo>
									</li>
								</ul>
							</div>
							<!--发送内容-->
							<div style="height: calc(100% - 70px)">
								<textarea v-on:focus="expressionShow = false" id="dope" v-model="sendData"
									class="textBox" v-on:keyup.enter="enterSend"></textarea>
								<button class="sendBtn" id="serviceSendBtn" v-on:click="sendMessage(sendData, 1)">
									发送(s)
								</button>
							</div>

						</div>
					</div>
				</div>
				<!--右边内容-->
				<ServiceRightPage v-if="isSelectSession" @submit="selectReplay($event)" :user="selectUser">
				</ServiceRightPage>
			</div>
		</div>
	</div>
	<InfoList v-if="showInfo" @closeInfo="closeInfo" :kind="activeBar"></InfoList>
	<BatchSend v-if="showBatch" @closeBatch="closeBatch" :kind="activeBar" :serviceId="service.serviceId"></BatchSend>
	<BlackList v-if="showBlackList" @closeBlackList="closeBlackList" :kind="activeBar"></BlackList>
	<QrCode v-if="showQrCode" @closeQrCode="closeQrCode" :serviceId="service.serviceId"></QrCode>
	<MyProfile v-if="showProfile" @closeProfile="closeProfile" :serviceId="service.serviceId"></MyProfile>
	<AccessStat v-if="showStat" @closeStat="closeStat"></AccessStat>
	<CardQry v-if="showCardQry" @closeCardQry="closeCardQry"></CardQry>
</template>

<script>
	import MessageWindow from '@/components/MessageWindow.vue';
	import SendEmote from '@/components/SendEmote.vue';
	import SendImage from '@/components/SendImage.vue';
	import SendVideo from '@/components/SendVideo.vue';
	import ServiceRightPage from '@/components/ServiceRightPage.vue';
	import InfoList from '@/components/InfoList.vue';
	import BatchSend from '@/components/BatchSend.vue';
	import BlackList from '@/components/BlackList.vue';
	import QrCode from '@/components/QrCode.vue';
	import MyProfile from '@/components/MyProfile.vue';
	import AccessStat from '@/components/AccessStat.vue';
	import CardQry from '@/components/CardQry.vue';
	import moment from 'moment';
	import config from '../config';

	let audio_on = new Audio('/audio/online.mp3');
	let audio_off = new Audio('/audio/offline.mp3');

	export default {
		components: {
			MessageWindow,
			SendEmote,
			SendImage,
			SendVideo,
			ServiceRightPage,
			InfoList,
			BatchSend,
			BlackList,
			QrCode,
			MyProfile,
			AccessStat,
			CardQry
		},

		data() {
			return {
				showInfo: false,
				showBatch:false,
				showBlackList: false,
				showQrCode: false,
				showProfile: false,
				showStat: false,
				showCardQry: false,
				activeBar: {},
				barList: [{
						id: 0,
						text: "二维码",
						state: false,
					},
					{
						id: 1,
						text: "打招呼",
						state: true,
					},
					{
						id: 2,
						text: "快捷回复",
						state: false,
					},
					{
						id: 3,
						text: "智能问答",
						state: false,
					},
					{
						id: 4,
						text: "群发",
						state: false,
					},
					{
						id: 5,
						text: "黑名单",
						state: false,
					},
					{
						id: 6,
						text: "状态查询",
						state: false,
					},
					{
						id: 7,
						text: "统计",
						state: false,
					},
					{
						id: 8,
						text: "设置",
						state: false,
					},
					{
						id: 9,
						text: "退出登录",
						state: false,
					}
				],
				socket: this.$store.state.serviceSocket,
				service: {
					serviceId: '',
					serviceName: '',
					serviceState: 0,
					serviceFrequency: 0
				},
				users:[],
				selectUser:{},
				actions: [{
					text: '退出登录'
				}],
				headImg:'',
				mode:true,
				sendData: '',
				reviceMessage: '',
				isSelectSession: false,
				isSelectShow: true,
				expressionShow: false,
			}
		},

		mounted() {
			//初始化
			this.initialization();

			this.socket.on("getFriendListReturn", (res) => {
				this.users=JSON.parse(res[0].data)
				/* for (var i=0;i<this.users.length;i++){
					if (this.users[i].userState==2){
						let user={
							sendId:this.users[i].userId,
							receiveId:this.service.serviceId
						}
						this.socket.emit("getUnreadMsgCount", user);
					}
				} */
			});
			
			this.socket.on("getFriendInfoReturn", (res) => {
				this.selectUser=JSON.parse(res[0].data)
				this.selectUser.userCreateTime= moment(this.selectUser.userCreateTime).format('YYYY-MM-DD HH:mm:ss') ;
				this.selectUser.joinTime= moment(this.selectUser.joinTime).format('YYYY-MM-DD HH:mm:ss') ;
				this.selectUser.leaveTime= this.selectUser.leaveTime==null?'':moment(this.selectUser.leaveTime).format('YYYY-MM-DD HH:mm:ss') ;
				this.selectUser.isSelectSession=true;
				this.selectUser.messageList=[];
				/* if (this.mode){
					if (){}else{}
					this.selectUser.mode=1;
					this.showMsgList();
				}else{
					this.selectUser.mode=2;
					this.showMsgList();
				} */
				this.showMsgList();
			});
			
			/* this.socket.on("getUnreadMsgCountReturn", (res) => {
				if (res[0].code){
					let msgCount=JSON.parse(res[0].data)
					for (var i=0;i<this.users.length;i++){
						for (var j=0;j<msgCount.length;j++){
							if (this.users[i].userId==msgCount[j].sendId){
								this.users[i].UnRead=msgCount[j].num;
							}
						}
					}
				}
			}); */
			
			this.socket.on("getMsgListReturn", (res) => {
				if (res[0].code){
					let msg=JSON.parse(res[0].data)				
					if (this.selectUser!={}){
						this.selectUser.messageList=[];
						for (var j=0;j<msg.length;j++){
							let obj = {
								sendType: 5,
								sendPeople: 'notice',
								message: msg[j].sendTime
							}
	
							this.selectUser.messageList.push(obj)
							let sendPeople='me';
							if (this.selectUser.userId==msg[j].sendId){
								sendPeople='other'
							}
							obj = {
								sendType: msg[j].sendType,
								sendPeople: sendPeople,
								message: msg[j].sendMessage
							};
							this.selectUser.messageList.push(obj);
						}
						this.toBottom(128)
					}
				}
			});
						
			this.socket.on("userLoginReturn", (res) => {
				if (res[0].code){
					audio_on.play();
					this.service.serviceFrequency = this.service.serviceFrequency + 1;
					let obj=res[0].data
					let user=[];
					user=this.users.filter(v => (v.userId == obj.userId));
					if (user.length>0){
						user[0].userState=2;
						if (this.isSelectSession){
							let data={
								sendId:this.selectUser.userId,
								receiveId:this.selectUser.serviceId
							}
							this.socket.emit('getFriendInfo',data);
						}						 
					}else{
						this.users.unshift(obj);
					}
				}
			});

			//用户连接成功通知
			/* this.socket.on("UserJoinSuccess", (data) => {
				audio_on.play();
				data[0].data.UnRead = 1
				data[0].data.message = "加入了会话"
				data[0].data.messageList = []
				data[0].data.isSelectSession = false
				this.service.serviceFrequency = this.service.serviceFrequency + 1;
			}); */

			//接收消息
			this.socket.on("reviceMessage", (data) => {
				for (var i=0;i<this.users.length;i++){
					if (this.users[i].userId==data[0].data.userId){
						//添加红点
						this.users[i].UnRead = this.users[i].UnRead + 1;
					}						
				}
				
				if (this.selectUser!={}){
					if (this.selectUser.userId == data[0].data.userId) {
						//左侧列表信息更新
						this.selectUser.message = data[0].data.message;
						//新创建一个列表，将信息存入列表，用于聊天窗口循环
						let obj = {
							sendType: 5,
							sendPeople: 'notice',
							message: data[0].data.time
						}
						this.selectUser.messageList.push(obj)
						let sendPeople='other';
						if (data[0].data.sendUserType==3){
							sendPeople='me'
						}
						obj = {
							sendType: data[0].data.sendType,
							sendPeople: sendPeople,
							message: data[0].data.message
						};
						this.selectUser.messageList.push(obj)
					}
				}
				this.reviceMessage = data[0].data.message;
				this.toBottom(128)
			});

			//客户离线处理
			this.socket.on("userOffline", (res) => {
				audio_off.play();
				if (res[0].code){
					let user = [];
					user=this.users.filter(v => (v.userId == res[0].data.userId))
					if (user.length>0){
						user[0].userState=1;
						if (user[0].userId==this.selectUser.userId){
							let data={
								sendId:this.selectUser.userId,
								receiveId:this.selectUser.serviceId
							}
							this.socket.emit('getFriendInfo',data);
						}
					}
				}
			});
			
			//客服离线处理
			this.socket.on("Offline", () => {
				this.socket.close()
			});

			//错误通知
			this.socket.on("error", (data) => {
				this.$toast(data[0].message);
			});

			//成功通知
			this.socket.on("success", (data) => {
				this.$toast(data[0].message);
			});

		},
		methods: {
			initialization() {
				//加载数据,正常流程进入此页面，会拿到数据，假如浏览器不支持或被禁止存储，或因为各种迷惑行为导致没拿到数据，直接退出登录
				if (JSON.parse(localStorage.getItem('serviceData')) != null) {
					//此处修改对象会丢失响应式监听，不会触发视图更新，因此要显式拷贝对象
					this.service = Object.assign({}, JSON.parse(localStorage.getItem('serviceData')))
					this.headImg = this.service.headImg == '' || this.service.headImg == undefined ? config.apiUrl +
						'/upload/images/head.png' : config.apiUrl + this.service.headImg;
					this.socket.emit("serviceOnline", this.service);
				} else {
					this.$toast("浏览器不兼容！")
					this.loginOut();
				}
			},
			
			changeMode(){
				//this.mode=this.mode;
				this.showMsgList();
			},
			
			showMsgList(){
				let mode=1;
				let accessId='';
				if (this.mode){
					if (this.selectUser.userState==2){
						accessId=this.selectUser.accessId
					}
				}else{
					mode=2;
					if (this.selectUser.userState==2){
						accessId=this.selectUser.accessId
					}
				}
				let data={
					userId:this.selectUser.userId,
					accessId:accessId,
					mode:mode
				}
				this.socket.emit("getMsgList", data);
			},

			closeInfo() {
				this.showInfo = false;
			},
			
			closeBatch(data) {
				this.showBatch = false;
				if (this.selectUser!={}){
					if (this.selectUser.userId == data.userId) {
						//左侧列表信息更新
						this.selectUser.message = data.message;
						//新创建一个列表，将信息存入列表，用于聊天窗口循环
						let obj = {
							sendType: 5,
							sendPeople: 'notice',
							message: data.time
						}
						this.selectUser.messageList.push(obj)
						obj = {
							sendType: 1,
							sendPeople: 'me',
							message: data.message
						};
						this.selectUser.messageList.push(obj)
					}
				}
				this.reviceMessage = data.message;
				this.toBottom(128)
			},
			
			closeBlackList() {
				this.showBlackList = false;
			},

			closeQrCode() {
				this.showQrCode = false;
			},

			closeProfile() {
				this.showProfile = false;
			},

			closeStat() {
				this.showStat = false;
			},

			closeCardQry() {
				this.showCardQry = false;
			},

			changeBar(item) {
				this.activeBar = item;
				switch (this.activeBar.id) {
					case 0:
						this.showQrCode = true;
						break;
					case 4:
						this.showBatch = true;
						break;
					case 5:
						this.showBlackList = true;
						break;
					case 6:
						this.showCardQry = true;
						break;
					case 7:
						this.showStat = true;
						break;
					case 8:
						this.showProfile = true;
						break;
					case 9:
						this.onExit();
						break;
					default:
						this.showInfo = true;
				}
			},

			//退出登录，删除token  
			loginOut() {
				//删除vuex
				// this.$store.state.token = null;
				//删除localStorage
				localStorage.setItem('token', null);
				//去登录页面
				this.$router.push({
					path: '/customerServiceLogin',
					replace: true
				})
			},
			//列表弹出选择
			onExit() {
				this.loginOut()
			},

			//客服选择会话
			selectSession(obj) {
				//隐藏
				this.isSelectShow = false
				//点击之后显示聊天窗口
				this.isSelectSession = true
				//点击之后取消显示红点
				for (var i = 0; i < this.users.length; i++) {
					this.users[i].isSelectSession = false
					if (this.users[i].userId == obj.userId) {
						this.users[i].isSelectSession = true;
						let data={
							sendId:obj.userId,
							receiveId:this.service.serviceId
						}
						this.socket.emit("setMsgRead",data);
						this.users[i].UnRead=0;
						this.socket.emit("getFriendInfo", data);
					}
				}
			},

			//客服发送消息
			sendMessage(data, sendType) {
				//判断发送类型
				if (sendType === 1 && this.sendData.length <= 0) {
					this.$toast("内容不能为空!");
					return;
				}
				if (sendType === 2 && this.$route.path === '/customerService') {
					this.expressionShow = !this.expressionShow;
				}
				//向socket发送数据请求
				this.service.message = data;
				this.service.socketRoom = this.selectUser.socketRoom;
				this.service.receiveId = this.selectUser.userId;
				
				this.service.sendType = sendType;
				this.service.sendUserType = 1;
			
				this.socket.emit("sendMessage", this.service);
				//将数据存入与这个用户的聊天信息列表
				let obj = {
					sendType: 5,
					sendPeople: 'notice',
					message: moment().format('YYYY-MM-DD HH:mm:ss')
				}
				this.selectUser.messageList.push(obj)
				obj = {
					sendType: sendType,
					sendPeople: 'me',
					message: data
				}
				this.selectUser.messageList.push(obj)
				//清空输入框
				this.sendData = '';
				//让聊天窗口回到底部
				this.toBottom(128)
			},

			//客服选择快捷回复
			selectReplay(text) {
				this.sendData = text;
			},


			//回到底部
			toBottom(time) {
				setTimeout(() => {
					let RightCont = document.getElementById("RightCont");
					if (RightCont != null) {
						let scrollHeight2 = RightCont.scrollHeight;
						RightCont.scrollTop = scrollHeight2;
					}
				}, time);
				clearTimeout();
			},


		}
	}
</script>


<style scoped>
	@import url("../assets/css/CustomerService.css");
</style>